<template>
  <div
    class="flex flex-col justify-between w-16 h-full bg-white border-r dark:bg-slate-900 border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0"
  >
    <div class="flex flex-col items-center mt-4">
      <div class="mb-10">
        <div class="w-8 h-8 bg-center bg-cover rounded-full bg-supa-peach">
          <logo
            :source="logoSource"
            :name="installationName"
            :account-id="accountId"
            class="mx-auto mb-10"
          />
        </div>
      </div>

      <template v-for="menuItem in menuItems.filter(x => !x.mobile_only)">
        <!-- render only if has_created_bot is true -->
        <primary-nav-item
          v-if="hasCreatedBot"
          :key="menuItem.toState"
          :icon="menuItem.icon"
          :name="menuItem.label"
          :to="menuItem.toState"
          :is-child-menu-active="menuItem.key === activeMenuItem"
        />
      </template>
    </div>
    <div class="flex flex-col items-center justify-end pb-6">
      <a
        v-if="labUrl"
        class="relative flex items-center justify-center w-10 h-10 my-2 rounded-lg text-slate-700 dark:text-slate-100 hover:bg-mila-peach100 hover:text-mila-peach has-tooltip"
        :href="labUrl"
        target="_blank"
      >
        <fluent-icon icon="beaker" />
      </a>
      <a
        class="relative flex items-center justify-center w-10 h-10 my-2 rounded-lg text-slate-700 dark:text-slate-100 hover:bg-mila-peach100 hover:text-mila-peach has-tooltip"
        href="https://docs.google.com/document/d/e/2PACX-1vQT_-eJ2JhQAtBHEDrkDLOEPrCrHtFg6GWmFG8yFkwS0uddMAKRFnTRSdBw9_zxDy-yLoq4HaHNERpD/pub"
        target="_blank"
      >
        <font-awesome-icon icon="fa-solid fa-info" />
      </a>
      <notification-bell @open-notification-panel="openNotificationPanel" v-if="!isMobile"/>
      <agent-details @toggle-menu="toggleOptions"/>
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptionsMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      getHasCreatedBot: 'accounts/getHasCreatedBot',
      globalConfig: 'globalConfig/get',
      uiFlags: 'accounts/getUIFlags',
      environment: 'environment/getEnv',
    }),
    // TODO: Remove this. This is a temporary fix for the issue where the bot is not created
    // But bot creation is already moved signup flow
    hasCreatedBot() {
      return this.getHasCreatedBot(this.accountId);
    },
    isMobile() {
      return screen.width <= 760;
    },
    // Url for experimental microsite
    labUrl() {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) {
        return null;
      }

      return `${this.environment.VUE_APP_MILA_LAB_URL}/accounts/${this.accountId}/conversation_analytics/${accessToken}`;
    },
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
  },
};
</script>
